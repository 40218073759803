<template>
  <div>
    <el-upload
      style="text-align: left"
      action="#"
      :show-file-list="false"
      :before-upload="beforeUpload"
      :http-request="httpRequest"
      list-type="picture-card"
      :accept="iconInfo.acceptImage"
      v-loading="loading"
      element-loading-text="图片正在上传中，请稍等"
    >
      <img
        v-if="iconInfo.icon"
        :src="iconInfo.icon"
        style="width: 144px; height: 144px; margin-top: 1px"
      />
      <i v-else class="el-icon-plus"></i>
      <div class="el-upload_tip" slot="tip">
        {{ tipss }}
      </div>
    </el-upload>
  </div>
</template>
<script>
import { beforeUploadCom } from '@/components/myself/UploadCommon/uploadImg.js'
import { UploadOssResource } from '@/libs/ossUpload/getUrl'
import { SpecChar } from '@/components/myself/speciaChaFilt.js'
import { NameRules } from './nameRule.js'
export default {
  props: {
    iconInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    tipss: {
      type: String
    },
    sizeImg: {
      type: String,
      default: '10'
    },
    nameFlag: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      uploadSuccess: false,
      loading: false
    }
  },
  methods: {
    // 图片1
    async beforeUpload (img) {
      if (NameRules(img.name, this.tipss, this.iconInfo.type, this.nameFlag) && SpecChar(img.name)) {
        let result = await beforeUploadCom(this.iconInfo.acceptImage, img, Number(this.sizeImg))
        this.uploadSuccess = result.flag
        this.iconInfo.iconSize = result.IconSize
        return result.flag
      } else {
        this.uploadSuccess = false
      }
    },

    async httpRequest (data) {
      if (!this.uploadSuccess) return
      this.loading = true
      const res = await UploadOssResource(data.file, this.iconInfo.type)
      this.iconInfo.icon = res.url
      this.iconInfo.IconObject = { pathName: res.url2, resourceType: this.iconInfo.type, size: data.file.size, dimension: this.iconInfo.iconSize }
      this.loading = false
      this.$forceUpdate()
    },
  }
}
</script>

