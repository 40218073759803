import ElementUI from 'element-ui'

// 上传图片时获取宽高
export const loadImage = (img) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = function (e) {
      let image = new Image()
      image.src = e.target.result
      image.onload = function () {
        let obj = {
          width: image.width,
          height: image.height
        }
        resolve(obj)
      }
    }
    reader.readAsDataURL(img)
    img.onerror = reject
  })
}

// 上传图片验证,radioIndex个人商品上传时区分
// 0:模板，1：组件，2：素材
export const beforeUploadCom = async (acceptImage, img, sizeImg = 10) => {
  let flag = false
  if (!(['image/jpeg', 'image/png', 'image/gif', 'image/jpg', 'image/svg+xml', 'image/x-icon', 'image/bmp'].includes(img.type))) {
    let str = acceptImage ? acceptImage : 'JPG/JPEG/PNG/GIF/SVG/ICON/BMP'
    ElementUI.Message.error('上传图片仅支持 ' + str + ' 格式!')
    return { flag }
  }

  if (!(img.size < 1024 * 1024 * sizeImg)) {
    ElementUI.Message.error('上传图片大小不能超过 ' + sizeImg + 'MB!')
    return { flag }
  }

  let image = await loadImage(img)
  let IconSize = image.width + '*' + image.height
  flag = true
  return { flag, IconSize }
}