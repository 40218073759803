import { getSignature } from '@/api/Uploadurl'
import { OSSUpload } from '@/libs/ossUpload/ossClient'
import ElementUI from 'element-ui'

// 1：图片，2：文件
export const UploadOssResource = async (file, type) => {
  const res = await getSignature(type)

  if (res.status !== 200) return ElementUI.Message.error('获取上传凭证失败')
  if (res.data.status !== 200) return ElementUI.Message.error(res.data.message)
  let accessid = res.data.data.accessid
  let policy = res.data.data.policy
  let signature = res.data.data.signature
  let dir = res.data.data.dir
  let host = res.data.data.host
  const ossupload = await OSSUpload(host, accessid, policy, signature, dir, file.name, file)
  if (ossupload.status !== 200) return ElementUI.Message.error('上传失败')
  let url = host + '/' + dir + file.name
  let url2 = dir + file.name

  return {
    url, url2
  }
}