// import OSS from 'ali-oss'
// const OSS = require('ali-oss')

// export function OSSclient (region, accessKeyId, accessKeySecret, bucket) {
//   //后端提供数据
//   return new OSS({
//     // 这是直接传
//     region: region,  //'创建bucket的地区'
//     accessKeyId: accessKeyId, //账户标识
//     accessKeySecret: accessKeySecret, //账户秘钥
//     bucket: bucket //bucket的名字
//   })
// }

// export const OSSUpload = async (region, accessKeyId, accessKeySecret, bucket, filename, file, progressBar) => {
//   this.OssClient(region, accessKeyId, accessKeySecret, bucket).multipartUpload(filename, file, {
//     progress: function (p) { //获取进度条的值
//       progressBar = p * 100
//       console.log('进度条：', p, progressBar)
//     }
//   }).then(
//     result => {
//       //下面是如果对返回结果再进行处理，根据项目需要
//       console.log('oss返回结果：', result)
//     }).catch(err => {
//       console.log("err:", err)
//     })
//   return progressBar
// }


// // 后端返回凭证，再传

import axios from 'axios'
export const OSSUpload = async (host, accessid, policy, signature, dir, filename, file) => {

  let param = new FormData()
  param.append('key', dir + filename)
  param.append('OSSAccessKeyId', accessid)
  param.append('policy', policy)
  param.append('signature', signature)
  param.append('success_action_status', 200)
  param.append('file', file)
  let config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  }
  return new Promise((resolve, reject) => {
    axios.post(host, param, config).then(response => {
      let showdata = {}
      if (response.status === 200) {
        showdata = {
          status: response.status,
          msg: '上传成功'
        }
        resolve(showdata)
      } else {
        reject(response)
      }
    }).catch((error) => {
      reject(error)
    })
  })
}
