import ElementUI from 'element-ui'

export const SpecChar = (name) => {
  // @ . 空格 可以允许
  // let reg = /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g 

  // 规则对象(flag)
  var flag = new RegExp("[`~!#$^&*()=|{}':;',\\[\\]<>《》/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]");
  if (flag.test(name)) {
    ElementUI.Message.error('名称不能包含除‘@. ’外的特殊字符')
    return false
  }
  return true
}

//输入框 验证规则 ,此处是因为数据库中插入$特殊符号报错
export const validatorInput = (rule, value, callback) => {
  var flag = new RegExp("[$]")
  if (flag.test(value)) {
    callback(new Error('输入内容不能包含$特殊字符'))
  } else {
    callback()
  }
}


//手机验证规则
export const validatorPhone = (rule, value, callback) => {
  var userMobile = /^1[34578]\d{9}$/
  if (!userMobile.test(value)) {
    callback(new Error('请输入正确的手机号'))
  } else {
    callback()
  }
}

//邮箱验证规则
export const validatorEmail = (rule, value, callback) => {
  var userEmail = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
  if (!userEmail.test(value)) {
    callback(new Error('请输入有效的邮箱'))
  } else {
    callback()
  }
}