import ElementUI from "element-ui";

/**
 * @description: 上传文件名规则
 * @param {String} name 文件名 eg: relaper-studio_setup_1.10.1_win.exe
 * @param {String} tips 提示语 eg: 文件名以’_操作系统‘结尾，如test_win.exe
 * @param {String} resourceType 资源类型 eg: windows-pack
 * @param {Boolean} nameFlag 是否需要校验
 */
export const NameRules = (name = "", tips, resourceType, nameFlag) => {
  if (nameFlag) {
    // 结尾必须是_系统版本，win,mac,linux,
    if (name.search("_") == -1) {
      ElementUI.Message.error(tips);
      return false;
    }
    const curOsType = (name.split("_").pop().split(".")[0] || "").toLowerCase();
    let os = resourceType.split("-")[0];
    const result = curOsType.length >= 3 && os.includes(curOsType);
    if (!result) {
      ElementUI.Message.error(tips);
    }
    return result;
  } else {
    return true;
  }
};
