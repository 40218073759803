<template>
  <div>
    <TinymceEditor
      v-model="msg"
      :disabled="disabled"
      @onClick="onClick"
      @input="input"
      ref="editor"
      :title="title"
    ></TinymceEditor>
    <!-- <el-button
      @click="clear"
      type="danger"
      style="margin-top: 10px"
      class="buttonDiv"
      v-show="ShowButton"
      >清空内容</el-button
    > -->
    <el-button
      @click="closes"
      style="margin-top: 10px"
      class="buttonDiv"
      v-show="ShowButton"
      >取消</el-button
    >
    <el-button
      @click="saves"
      type="primary"
      class="buttonDiv"
      v-show="ShowButton"
      >保存</el-button
    >
    <!-- <el-button
      @click="Submits"
      type="warning"
      class="buttonDiv"
      v-show="ShowButton"
      >提交审核</el-button
    > -->
    <!-- <el-button @click="disabled = true">禁用</el-button> -->
  </div>
</template>

<script>
import TinymceEditor from './TinymceEditor.vue'
import ListenEvent from '@/libs/ListenEvent.js'

export default {
  components: { TinymceEditor },
  props: {
    title: {
      type: String,
      default: ''
    },
    ShowButton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      msg: '',
      disabled: false
    }
  },
  mounted () {
    ListenEvent.$on('msgShow', (para) => {
      if (para.search('<p>') !== -1) {
        this.msg = para
      }
    })
  },
  methods: {
    // 鼠标单击事件
    onClick (e, editor) {
      console.log(e, editor)
    },
    // 输入的内容
    input (value) {
      this.$emit('input', value)
    },
    // clear () {
    //   this.$refs.editor.clear()
    // },
    saves () {
      this.$emit('saves')
    },
    closes () {
      this.$emit('closes')
    }
    // Submits () { }
  }
}
</script>
<style lang="less" scoped>
.buttonDiv {
  font-size: 16px;
  // border-radius: 30px;
}
</style>