<template>
  <div class="tinymce">
    <h1>{{ title }}</h1>
    <editor
      v-model="tinymceHtml"
      :init="init"
      :disabled="disabled"
      @onClick="onClick"
      ref="tinymce"
    >
    </editor>

    <el-upload
      ref="uploadRef"
      action="#"
      :before-upload="beforeUpload"
      :show-file-list="false"
      :disabled="disabled"
      :accept="acceptFile"
    >
    </el-upload>
  </div>
</template>

<script>
import "mammoth/mammoth.browser.js"
import Mammoth from "mammoth"
import { UploadOssResource } from '@/libs/ossUpload/getUrl'
import { getbase64image, base64ToFile } from './imgToFile'

import tinymce from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'

// 引入富文本编辑器主题的js和css
// import 'tinymce/themes/silver/theme'
import 'tinymce/themes/silver/theme.min.js'
import 'tinymce/skins/ui/oxide/skin.min.css'

// 扩展插件
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/image'
import 'tinymce/plugins/imagetools'
import 'tinymce/plugins/media'
import 'tinymce/plugins/table'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/contextmenu'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/colorpicker'
import 'tinymce/plugins/textcolor'
import 'tinymce/plugins/link'
import 'tinymce/plugins/code'
import 'tinymce/plugins/help'
import 'tinymce/plugins/toc'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/print'
import 'tinymce/plugins/save'
import 'tinymce/plugins/anchor'
import 'tinymce/plugins/fullpage'
import 'tinymce/plugins/emoticons'
import 'tinymce/plugins/autosave'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/directionality'
import 'tinymce/plugins/hr'
import 'tinymce/plugins/pagebreak'
import 'tinymce/plugins/searchreplace'
import 'tinymce/plugins/template'
import 'tinymce/plugins/paste'
import 'tinymce/icons/default/icons.min.js'



export default {
  components: { Editor },
  props: {
    title: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    plugins: {
      type: [String, Array],
      default: 'template searchreplace pagebreak hr emoticons directionality charmap link lists image imagetools emoticons fullpage code table colorpicker textcolor wordcount contextmenu fullscreen preview help toc insertdatetime print save anchor media autosave paste'
    },
    toolbar: {
      type: Array,
      default: () => ['toc | charmap bold italic underline strikethrough subscript superscript| formatselect fontselect fontsizeselect  | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent blockquote | undo redo | link unlink image media code | insertdatetime cut copy paste restoredraft | searchreplace  emoticons| fullscreen preview | help | FileUpload']
    },
    Height: {
      type: Number,
      default: 600
    },
    limitSize: {
      type: Number,
      default: 500
    }
  },
  data () {
    let that = this
    return {
      acceptFile: '.doc,.docx',
      uploadFlag: false,
      tinymceHtml: this.value,
      init: {
        language_url: '/tinymce/langs/zh_CN.js',
        language: 'zh_CN',
        skin_url: '/tinymce/skins/ui/oxide',
        height: this.Height,
        plugins: this.plugins,
        toolbar: this.toolbar,
        emoticons_database_url: '/tinymce/plugins/emoticons/js/emojis.js',
        // paste
        paste_data_images: true,
        paste_enable_default_filters: false,

        // toolbar_mode: 'sliding',
        font_formats: "微软雅黑='微软雅黑';宋体='宋体';黑体='黑体';仿宋='仿宋';楷体='楷体';隶书='隶书';幼圆='幼圆';Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings",
        // 去水印
        branding: false,
        // 拼写错误
        browser_spellcheck: true,

        // 菜单栏的显示
        menubar: true,
        file_picker_types: 'image media file',

        setup: function (editor) {
          editor.on('paste', function async (event) {
            // 监听粘贴事件
            const doc = editor.getDoc();
            that.onPaste(event, doc)
          })

          // 注册按钮
          editor.ui.registry.addButton('FileUpload', {
            icon: `upload`,
            tooltip: '导入文件',
            text: '导入文件',
            onAction: () => {
              that.$refs['uploadRef'].$refs['upload-inner'].handleClick()
            }
          })
        },

        // 图片上传处理函数
        images_upload_handler: async (blobInfo, success, failure) => {
          that.uploadFlag = true
          // const img = 'data:image/jpeg;base64,' + blobInfo.base64()
          const res = await UploadOssResource(blobInfo.blob(), 'news')
          success(res.url)
          failure('error')
        },

        file_picker_callback: (callback, value, meta) => {
          const input = document.createElement('input')
          input.setAttribute('type', 'file')
          let fileType = '.pdf,.txt,.zip,.rar,.7z,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.mp3,.mp4'

          if (meta.filetype === 'media') {
            fileType = '.mp4,.wmv,.avi,.rm,.rmvb,.mp3,.wma'
          } else if (meta.filetype === 'image') {
            fileType = '.png,.jpg,.jpeg,.gif'
          }
          input.setAttribute('accept', fileType)
          input.onchange = async () => {
            const file = input.files[0]
            const res = await UploadOssResource(file, 'news')
            callback(res.url)
          }
          input.click()
        },
      }
    }
  },
  mounted () {
    tinymce.init({})
  },
  watch: {
    value (newValue) {
      this.tinymceHtml = newValue
    },
    tinymceHtml (newValue) {
      this.$emit('input', newValue)
    }
  },
  methods: {
    // 添加相关事件
    onClick (e) {
      this.$emit('onClick', e, tinymce)
    },
    clear () {
      this.tinymceHtml = ''
    },

    onPaste (event, doc) {
      // 粘贴内容中获取图片
      setTimeout(async () => {
        const items = doc.getElementsByTagName('img')
        if (items.length) {
          for (let img of items) {
            // 触发上传接口，就不需要走此处
            if (!this.uploadFlag) {
              var src = img.getAttribute('_src') || img.src || '';
              let name = src.split('/')[src.split('/').length - 1]
              // console.log('---', this.uploadFlag, name, src, typeof img, img)

              try {
                let url = ''
                if (src.search('file://') !== -1) {
                  // 本地图片 复制 ，图文、多图情况下，设置一个默认路径：C:/Users/admin/Pictures/,warning是文件后缀要静态
                  // url = require('C:/Users/admin/Pictures/' + name.split('.')[0] + '.jpg')
                  // url = require('C:/Users/admin/Pictures/' + name)
                  console.log('暂不支持本地图文组合复制')

                  // const images = require.context('C:/Users/admin/Pictures/', false, /\.*$/)
                  // images.keys().forEach(key => {
                  //   if (key.includes(name)) {
                  //     url = images(key).default || images(key)
                  //     console.log('324444444', key, url)
                  //   }
                  // })

                } else {
                  // 复制网上图片（别人服务器上的）
                  url = src
                }
                let dataURL = await getbase64image(img, url)
                let imgFile = base64ToFile(dataURL, name)
                UploadOssResource(imgFile, 'news')
              } catch (e) {
                console.log(e)
                // this.$message.error('C:/Users/admin/Pictures目录下不存在此文件')
              }
              this.uploadFlag = false
            }
          }
        }
      }, 100);
      this.uploadFlag = false

    },


    // 在确定上传之前，判断
    beforeUploadFile (file) {
      let fileType = file.name.substr(file.name.lastIndexOf("."));
      let fileMsg = this.acceptFile
      let extension = fileMsg.includes(fileType.toLowerCase())
      if (!extension) {
        return this.$message.error('上传的文件类型仅支持 ' + fileMsg + '格式!')
      }
      if (!(file.size < 1024 * 1024 * this.limitSize)) {
        return this.$message.error(`上传的文件大小不能超过 ${this.limitSize}MB!`)
      }
      return true
    },

    beforeUpload (file,) {
      if (this.beforeUploadFile(file) === true) {
        const self = this
        var reader = new FileReader()
        reader.onloadend = () => {
          let arrayBuffer = reader.result;
          const loading = self.$loading({
            lock: true,
            text: '文件解析中....',
            spinner: 'el-icon-loading'
          })
          //将word 转换成html
          var options = {
            // 图片上传
            convertImage: Mammoth.images.imgElement(image => {
              return image.read("base64").then(async (imageBuffer) => {
                const result = await base64ToFile('data:image/jpeg;base64,' + imageBuffer, 'img_' + Math.random() + '.jpeg')
                const res = await UploadOssResource(result, 'news')
                return { src: res.url }
              })
            })
          }
          Mammoth.convertToHtml({ arrayBuffer: arrayBuffer }, options).then(resultObject => {
            this.$nextTick(() => {
              let content = resultObject.value
              // let content = tinymce.activeEditor.getContent() + resultObject.value
              tinymce.activeEditor.setContent(content)
              loading.close()
            })
          })
        }
        reader.readAsArrayBuffer(file)
        return false
      }
    }
  }
}
</script>

