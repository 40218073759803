// 图片元素转base64
export const getbase64image = async (img, src) => {
  var canvas = document.createElement("canvas");
  var context = canvas.getContext('2d');
  // img.setAttribute('crossOrigin', 'anonymous');
  img.src = src
  return new Promise(function (resolve) {
    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;
      context.drawImage(img, 0, 0, img.width, img.height);
      //这里的dataurl就是base64类型
      var dataURL = canvas.toDataURL("image/jpeg")
      resolve(dataURL)
    }
  })
}

// base64格式转为File文件类型
export const base64ToFile = (urlData, fileName) => {
  let arr = urlData.split(',');
  let mime = arr[0].match(/:(.*?);/)[1];
  let bytes = atob(arr[1]); // 解码base64
  let n = bytes.length
  let ia = new Uint8Array(n);
  while (n--) {
    ia[n] = bytes.charCodeAt(n);
  }
  return new File([ia], fileName, { type: mime });
}


//  Base64 格式转成 Blob 对象
export function base64ToBlob (base64, mime) {
  mime = mime || "";
  const sliceSize = 1024;
  const byteChars = window.atob(base64);
  const byteArrays = [];
  for (
    let offset = 0, len = byteChars.length;
    offset < len;
    offset += sliceSize
  ) {
    const slice = byteChars.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);

    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: mime });
}
